//Bootstrap
@import "./bootstrap/scss/bootstrap";

//Components
@import "./blocks/layout";
@import "./blocks/sideBar";
@import "./blocks/dragAndDrop";
@import "./blocks/login";

//Containers
@import "./blocks/tags";

//General
.app {
  display: flex;
  width: 100%;
  align-items: stretch;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

/*
Colors

$white: #fff;
$black: #000;
$mcq: #ffd600;
$dark: #1c344c;
$dark-faded: #a4adb7;
$dark-grey: #323948;
$light: #b2b2b2;
$ultra-light: #f2f2f2;
$shadow: #323948;
$red: #bf0000;

*/

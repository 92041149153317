.tags {
  input[type="checkbox"] {
    pointer-events: none !important;
  }

  .image-container {
    position: relative;
    text-align: center;
    color: black;

    img {
      border-radius: 10px;
    }
  }

  .image {
    color: white;
  }

  .image::after {
    display: block;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    margin-top: -119px;
    height: 119px;
    width: 85%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    content: "";
  }

  .centered {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
  }
}

.side-bar {
  a,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

  .sidebar {
    min-width: 270px;
    max-width: 270px;
    background: #ffd600;
    color: #000;
    margin-left: -270px;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
  }

  .sidebar-header {
    background: #e6bf00;
  }

  .sidebar-header h3 {
    color: #000;
    padding: 1em;
  }

  .sidebar ul p {
    color: #000;
    padding: 10px;
  }

  .menu-open {
    background: #e6bf00;
  }

  .nav-item:hover {
    color: #bf0000;
    background: #fff;
  }

  .items-menu {
    color: #000;
    background: #e6bf00;
  }

  li div.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    margin-top: 6%;
    left: 15%;
  }

  .sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
  }
  .sidebar {
    min-height: 100vh;
    height: 100%;
    overflow-y: hidden;
  }

  .side-menu::-webkit-scrollbar {
    width: 10px;
  }

  .side-menu::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
  }

  .side-menu::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
  }

  .login-nav {
    margin-top: 56.7vh;
  }
}
